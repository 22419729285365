export default class Params {
  constructor({ pagination = null, id = null, filter = null }) {
    this.id = id;
    this.sortBy = pagination?.sortBy;
    this.descending = pagination?.descending;
    this.sortDir = pagination?.sortDir;
    this.page = pagination?.page;
    this.rowsPerPage = pagination?.rowsPerPage;
    this.totalPages = pagination?.totalPages;
    this.isValid = true;
    this.setFilter(filter);
  }

  setId(id) {
    this.id = id;
  }

  setProp(prop, value) {
    const idProps = ["id"];
    const paginationProps = [
      "sortBy",
      "descending",
      "sortDir",
      "page",
      "rowsPerPage",
      "totalPages",
    ];
    const filterProps = ["filter"];
    const notAllowedProps = [idProps, paginationProps, filterProps].flat();
    if (notAllowedProps.includes(prop)) {
      throw new Error(`Property "${prop}" not allowed`);
    }
    this[prop] = value;
  }

  setFilter(filter) {
    const pFilter = {};
    Object.entries(filter || {}).forEach(([prop, propValue]) => {
      if (!propValue) return;
      /** Handling Dropdown */
      if (typeof propValue === "object") {
        const value = [propValue.value, propValue.id].find(
          (val) => val !== undefined
        );

        if (value === null) return;
        if (value || typeof value === "boolean") {
          return (pFilter[prop] = value);
        }

        /** Handling Date Range */
        const { from, to } = propValue;
        if (from) pFilter[`${prop}From`] = from;
        if (to) pFilter[`${prop}To`] = to;
        if (from !== undefined && to !== undefined) return;
      }
      /** Handling Boolean */
      if (typeof propValue === "boolean") {
        return (pFilter[prop] = propValue);
      }
      /** Handling Number */
      if (typeof propValue === "number") {
        return (pFilter[prop] = propValue);
      }
      /** Handling Text */
      if (typeof propValue === "string" && propValue.length >= 3) {
        return (pFilter[prop] = propValue);
      }
      /** Handling Array */
      if (Array.isArray(propValue) && propValue.length) {
        return (pFilter[prop] = propValue.join(","));
      }
      this.isValid = false;
    });
    this.filter = Object.keys(pFilter).length ? pFilter : null;
  }

  getId() {
    return this.id;
  }

  getProp(prop) {
    return this[prop];
  }

  getParams() {
    const params = {};
    if (this.sortBy) params.sortBy = this.sortBy;
    params.descending = this.descending;
    this.descending ? (params.sortDir = "desc") : (params.sortDir = "asc");
    if (this.page) params.page = this.page;
    if (this.rowsPerPage) params.rowsPerPage = this.rowsPerPage;
    if (this.totalPages) params.totalPages = this.totalPages;
    return params;
  }

  getParamsWithFilter() {
    const params = this.getParams();
    return {
      ...params,
      ...(Object.keys(this.filter || {}).length ? this.filter : {}),
    };
  }

  getPageParams() {
    const params = {};
    params.sortBy = this.sortBy;
    params.descending = this.descending;
    params.page = this.page;
    params.rowsPerPage = this.rowsPerPage;
    params.sortDir = this.sortDir;
    params.totalPages = this.totalPages;
    return params;
  }
}
